<template>
	<div class="skill-item" @mouseover="$hideCursor" @mouseleave="$showCursor">
		<div class="skill-wrapper">
			<p><font-awesome-icon v-if="skill.icon !== undefined" :icon="skill.icon" size="lg" class="skillIcon" :style="{ color: skill.color }" /> {{ skill.name }}</p>
		</div>
		<p class="skillDesc">{{ skill.description }}</p>
	</div>
</template>

<script>
	export default {
		name: 'SkillCard',
		props: {
			skill: {
				name: {
					type: String
				},
				description: {
					type: String
				},
				icon: {
					type: Array
				},
				color: {
					type: String
				}
			}
		}
	}
</script>

<style scoped>
	.skill-item {
		background-color: var(--background-light);
		border-radius: 10px;
		padding: 10px 20px;
		width: 90%;
		position: relative;
		box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.18)
	}

	.skill-item p {
		margin: 0.5rem 0;
	}

	.skill-wrapper {
		display: flex;
		flex-direction: row;
	}

	.skill-wrapper p {
		margin-bottom: 0;
	}

	.skillIcon {
		font-size: 25px;
		margin-right: 5px;
	}

	.skillDesc{
		color: var(--font-light);
		margin-top: 0px;
	}
</style>